import React from "react"
import Page from "../components/Pages/Page"
import { APPS, COURSES, EBOOKS } from "../constants"
import { Label, Text } from "../components/atomics"
import { Link } from "gatsby"

const LinksPage = () => {
  const utm = '?utm_source=instaFlynow&utm_medium=links&utm_campaign=links'

  const sendEvent = event => {
    if (window) {
      if (window.fbq) window.fbq("track", event)
      if (window.gtag) window.gtag("event", event)
    }
  }
  return(
  <Page title="Links" pageId="links">
  <div style={{marginTop:20}}/>
  <h2 style={{margin:0, marginBottom: 10}}>Cursos</h2>
  {
    COURSES.map(i=> (
      <Link to={i.url + utm}
      onClick={()=> sendEvent('click_link_'+i.id)}>
      <div style={{
        display:  'flex',
        flexDirection: 'row',
        marginTop: 10,
      }}>
        <img src={i.img} alt={i.title} style={{height: 100}}></img>
        <div style={{marginLeft: 10}}>
        <Label>{i.title}</Label>
        <Text style={{fontSize: '1rem'}}>{i.description}</Text>
        </div>
      </div>
      </Link>
    ))
  }
  <h2 style={{marginTop:30, marginBottom: 10}}>Ebooks</h2>
  {
    EBOOKS.map(i=> (
      <Link to={i.url + utm}
      onClick={()=> sendEvent('click_link_'+i.id)}>
      <div style={{
        display:  'flex',
        flexDirection: 'row',
        marginTop: 10,
      }}>
        <img src={i.img} alt={i.title} style={{height: 100}}></img>
        <div style={{marginLeft: 10}}>
        <Label>{i.title}</Label>
        <Text style={{fontSize: '1rem'}}>{i.description}</Text>
        </div>
      </div>
      </Link>
    ))
  }
  <h2 style={{marginTop:30, marginBottom: 10}}>Apps</h2>
  {
    APPS.map(i=> (
      <Link to={i.url+ utm}
      onClick={()=> sendEvent('click_link_'+i.id)}>
      <div style={{
        display:  'flex',
        flexDirection: 'row',
        marginTop: 10,
      }}>
        <img src={i.img} alt={i.title} style={{height: 70}}></img>
        <div style={{marginLeft: 10}}>
        <Label>{i.title}</Label>
        <Text style={{fontSize: '1rem'}}>{i.description}</Text>
        </div>
      </div>
      </Link>
    ))
  }

  <h2 style={{marginTop:30, marginBottom: 20}}>Outros</h2>

  <Link to={"/blog"+ utm} style={{
    padding:10,
    border: '2px solid #333',
    borderRadius: 8,
    paddingBottom:15,
  }}
      onClick={()=> sendEvent('click_link_blog')}>
    <Label>Blog do Flynow</Label>
  </Link>
  <Link to={"/newsletter"+ utm} style={{
    padding:10,
    border: '2px solid #333',
    borderRadius: 8,
    paddingBottom:15,
    marginTop:10
  }}
      onClick={()=> sendEvent('click_link_blog')}>
    <Label>Newsletter</Label>
  </Link>
  <div style={{marginTop:50}}/>
  </Page>
)}

export default LinksPage
